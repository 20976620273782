document.addEventListener("turbolinks:load", () => {
  const commentDivs = document.querySelectorAll('.comment_content');

  commentDivs.forEach((commentDiv) => {
    const fullText = commentDiv.getAttribute('data-full-text');
    const readMoreThreshold = parseInt(commentDiv.getAttribute('data-read-more-threshold')) || 0;
    const readMoreOnlyOnMobile = parseInt(commentDiv.getAttribute('data-read-more-only-on-mobile')) || 0;

    const isMobile = window.innerWidth <= 480;

    let truncatedComment = fullText;

    if (readMoreOnlyOnMobile === 1) {
      if (isMobile && readMoreThreshold > 0 && fullText.split(' ').length > readMoreThreshold) {
        truncatedComment = fullText.split(' ').slice(0, readMoreThreshold).join(' ') + '...';
      }
    } else if (readMoreThreshold > 0 && fullText.split(' ').length > readMoreThreshold) {
      truncatedComment = fullText.split(' ').slice(0, readMoreThreshold).join(' ') + '...';
    }

    commentDiv.textContent = truncatedComment;

    const readMoreButton = commentDiv.parentElement.querySelector('.read_more_button');
    const readLessButton = commentDiv.parentElement.querySelector('.read_less_button');

    if (truncatedComment !== fullText) {
      readMoreButton.style.display = 'inline';
      readLessButton.style.display = 'none';
    }

    if (readMoreButton) {
      readMoreButton.addEventListener('click', () => {
        commentDiv.textContent = fullText;
        readMoreButton.style.display = 'none';
        readLessButton.style.display = 'inline';
      });
    }

    if (readLessButton) {
      readLessButton.addEventListener('click', () => {
        commentDiv.textContent = truncatedComment;
        readLessButton.style.display = 'none';
        readMoreButton.style.display = 'inline';
      });
    }
  });
});

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".copyLinkImage").forEach((icon) => {
    icon.addEventListener("click", () => {
      const section = icon.closest(".new_section");
      if (section) {
        const sectionId = section.id;
        const link = `${window.location}#${sectionId}`;
        navigator.clipboard.writeText(link).then(()=>{
          alert('link copied')
        })
      }
    });
  });
});

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll(".accordion-header").forEach((header) => {
    header.addEventListener("click", () => {
      const content = header.nextElementSibling;
      const icon = header.querySelector(".accordion-icon");
      const isOpen = content.style.maxHeight && content.style.maxHeight !== "0px";

      if (isOpen) {
        content.style.maxHeight = null;
        content.style.opacity = "0";
        icon.classList.remove("rotate-180");
        header.classList.add('new-accordion-open');
        header.classList.remove('new-accordion-close');
        content.classList.remove('accordion-content-open');
        header.parentElement.classList.remove('element-open');
        content.classList.remove('new_accordion_item_container_open_item')
      } else {
        content.classList.add('new_accordion_item_container_open_item')
        content.style.maxHeight = content.scrollHeight + 100 + "px";
        content.style.opacity = "1";
        icon.classList.add("rotate-180");
        header.classList.remove('new-accordion-open');
        header.classList.add('new-accordion-close');
        content.classList.add('accordion-content-open');
        header.parentElement.classList.add('element-open');
      }
    });
  });
});
